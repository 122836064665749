import { store } from '../store'
import { PLATFORM } from '../utils/enum/platform'
import api from './api'

export async function getDonations() {
  return api.get(`/donations`)
}

export async function getDonationValues(id) {
  return api.get(`/institutions/${id}/donation-values`)
}

export async function bankTickedDonation(cpf) {
  const body = {
    annonymousUser: true,
    cpf,
    latitude: store.getState().geolocation.latitude,
    longitude: store.getState().geolocation.longitude,
    value: store.getState().donation.amount,
    source: PLATFORM.web,
  }

  const { campaignId, institutionId } = store.getState().donation.recipient

  if (campaignId) body.campaignId = campaignId
  if (institutionId) body.institutionId = institutionId

  return api.post(`/donations/bank-ticket`, body)
}

export async function newCreditCardDonation({
  number,
  cpf,
  expirationMonth,
  expirationYear,
  holderName,
  securityCode,
  childId,
}) {
  const body = {
    annonymousUser: true,
    card_number: number,
    cpf,
    childId,
    expiration_month: expirationMonth,
    expiration_year: expirationYear,
    holder_name: holderName,
    institutionId: store.getState().institution.current.id,
    latitude: store.getState().geolocation.latitude,
    longitude: store.getState().geolocation.longitude,
    security_code: securityCode,
    value: store.getState().donation.amount,
    source: PLATFORM.web,
  }

  const { campaignId, institutionId } = store.getState().donation.recipient

  if (campaignId) body.campaignId = campaignId
  if (institutionId) body.institutionId = institutionId

  return api.post(`/donations/new-credit-card`, body)
}

export async function creditCardDonation({ cardId, childId }) {
  const body = {
    annonymousUser: true,
    cardId,
    childId,
    cpf: store.getState().user.profile.cpf,
    institutionId: store.getState().institution.current.id,
    latitude: store.getState().geolocation.latitude,
    longitude: store.getState().geolocation.longitude,
    value: store.getState().donation.amount,
    source: PLATFORM.web,
  }

  const { campaignId, institutionId } = store.getState().donation.recipient

  if (campaignId) body.campaignId = campaignId
  if (institutionId) body.institutionId = institutionId

  return api.post(`/donations/credit-card`, body)
}

export async function pixDonation() {
  const body = {
    institutionId: store.getState().institution.current.id,
    campaignId: 0,
    value: store.getState().donation.amount,
    annonymousUser: true,
    latitude: store.getState().geolocation.latitude,
    longitude: store.getState().geolocation.longitude,
  }

  const { campaignId, institutionId } = store.getState().donation.recipient

  if (campaignId) body.campaignId = campaignId
  if (institutionId) body.institutionId = institutionId

  return api.post(`/donations/pix`, body)
}
