/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layout'
import DonationsTable from '../../components/pages/Perfil/DonationsTable'
import Spacing from '../../components/Spacing'
import Title from '../../components/Title'
import Wrapper from '../../components/Wrapper'

const MyDonations = () => (
  <Layout>
    <Wrapper>
      <Spacing top="50" bottom="120">
        <Title as="h3" color="celestialblue" marginBottom="40">
          <FormattedMessage id="pages.profile.my_donations" />
        </Title>
        <DonationsTable />
      </Spacing>
    </Wrapper>
  </Layout>
)

export default MyDonations
