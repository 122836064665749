/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { navigate, FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import { toast } from 'react-toastify'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Table, DonationCard, DonationInfo } from '../styles'
import { setIsMaintenance } from '../../../../../store/modules/donation/actions'
import IconEdit from '../../../../../images/icons/id-edit.svg'
import IconDelete from '../../../../../images/icons/ic-delete.svg'

const RecurrentDonations = ({ donations: donationList }) => {
  const intl = useIntl()
  const [donations, setDonations] = useState(donationList || [])

  const dispatch = useDispatch()

  const handleEdit = async ({ institutionId, campaignId }) => {
    if (campaignId) {
      navigate(`/campanhas/doar/${campaignId}`)
      dispatch(setIsMaintenance(true))
    }

    if (institutionId) {
      navigate(`/instituicoes/doar/${institutionId}`)
      dispatch(setIsMaintenance(true))
    }

    toast.info(intl.formatMessage({ id: 'toast_messages.error.donation_edit' }))
  }

  const handleDelete = async id => {
    toast.info(
      intl.formatMessage({ id: 'toast_messages.success.donation_remove' })
    )
    setDonations(donations.filter(donation => donation.id !== id))
  }

  if (donations.length > 0) {
    return (
      <DonationInfo>
        <Table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="pages.profile.institution" />
              </th>
              <th>
                <FormattedMessage id="pages.profile.campaign" />
              </th>
              <th>
                <FormattedMessage id="pages.profile.date_initial" />
              </th>
              <th>
                <FormattedMessage id="pages.profile.value" />
              </th>
              <th>
                <FormattedMessage id="pages.profile.edit" />
              </th>
              <th>
                <FormattedMessage id="pages.profile.delete" />
              </th>
            </tr>
          </thead>
          <tbody>
            {donations.map(donation => (
              <tr key={donation.id}>
                <td>{donation.institutionName}</td>
                <td>{donation.campaignName}</td>
                <td>{donation.formattedDate}</td>
                <td>{donation.formattedValue}</td>
                <td>
                  <IconEdit
                    onClick={() =>
                      handleEdit({
                        institutionId: donation.institutionId,
                        campaignId: donation.campaignId,
                      })
                    }
                  />
                </td>
                <td>
                  <IconDelete onClick={() => handleDelete(donation.id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {donations.map(donation => (
          <DonationCard key={donation.id}>
            <h2>{donation.institutionName}</h2>
            {donation.campaignName ? (
              <span>{donation.campaignName}</span>
            ) : null}
            <div>
              <span>{donation.formattedDate}</span>
              <span className="value">{donation.formattedValue}</span>
            </div>
            <IconDelete onClick={() => handleDelete(donation.id)} />
          </DonationCard>
        ))}
      </DonationInfo>
    )
  }

  return (
    <div
      css={theme => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 60px 0;
        text-align: center;
      `}
    >
      <span>
        <FormattedMessage id="pages.profile.no_recurrent_donations_found" />
      </span>
    </div>
  )
}

RecurrentDonations.propTypes = {
  donations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isApproved: PropTypes.bool.isRequired,
      userId: PropTypes.number.isRequired,
      hasEvaluated: PropTypes.bool.isRequired,
      value: PropTypes.number.isRequired,
      institutionId: PropTypes.number.isRequired,
      campaignId: PropTypes.number.isRequired,
      institutionName: PropTypes.string.isRequired,
      campaignName: PropTypes.string.isRequired,
      createdDate: PropTypes.string.isRequired,
      userIsMaintainer: PropTypes.bool.isRequired,
      createdDateDonation: PropTypes.string.isRequired,
      valueDonated: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
      transactionId: PropTypes.string.isRequired,
      isRefunded: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      cardBrand: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      bankTicketNumber: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default RecurrentDonations
