/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Table, DonationCard, DonationInfo } from '../styles'
import IconPrint from '../../../../../images/icons/ic-print.svg'

const DonationsList = ({ donations }) => {
  if (donations.length > 0) {
    return (
      <DonationInfo>
        <Table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="pages.profile.code" />
              </th>
              <th>
                <FormattedMessage id="pages.profile.date" />
              </th>
              <th>
                <FormattedMessage id="pages.profile.institution" />
              </th>
              <th>
                <FormattedMessage id="pages.profile.campaign" />
              </th>
              <th>
                <FormattedMessage id="pages.profile.value" />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {donations.map(donation => (
              <tr key={donation.id}>
                <td>{donation.id}</td>
                <td>{donation.formattedDate}</td>
                <td>{donation.institutionName}</td>
                <td>{donation.campaignName}</td>
                <td>{donation.formattedValue}</td>
                <td style={{ textAlign: 'center' }}>
                  {donation.type === 'bank-ticket' ? (
                    <a href={donation.url}>
                      <IconPrint />
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {donations.map(donation => (
          <DonationCard key={donation.id}>
            <h2>{donation.institutionName}</h2>
            {donation.campaignName ? (
              <span>{donation.campaignName}</span>
            ) : null}
            <div>
              <span>{donation.formattedDate}</span>
              <span className="value">{donation.formattedValue}</span>
            </div>
            {donation.type === 'bank-ticket' ? (
              <a href={donation.url}>
                <IconPrint />
              </a>
            ) : null}
          </DonationCard>
        ))}
      </DonationInfo>
    )
  }

  return (
    <div
      css={theme => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 60px 0;
        text-align: center;
      `}
    >
      <span>
        <FormattedMessage id="pages.profile.no_donations_found" />
      </span>
    </div>
  )
}

DonationsList.propTypes = {
  donations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isApproved: PropTypes.bool.isRequired,
      userId: PropTypes.number.isRequired,
      hasEvaluated: PropTypes.bool.isRequired,
      value: PropTypes.number.isRequired,
      institutionId: PropTypes.number.isRequired,
      campaignId: PropTypes.number.isRequired,
      institutionName: PropTypes.string.isRequired,
      campaignName: PropTypes.string.isRequired,
      createdDate: PropTypes.string.isRequired,
      userIsMaintainer: PropTypes.bool.isRequired,
      createdDateDonation: PropTypes.string.isRequired,
      valueDonated: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
      transactionId: PropTypes.string.isRequired,
      isRefunded: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      cardBrand: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      bankTicketNumber: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default DonationsList
