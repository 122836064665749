import { store } from '../store'
import api from './api'

export async function getMaintenance() {
  return api.get(`/maintenances`)
}

export async function setMaintenance({ paymentType }) {
  const body = {
    paymentType,
    value: store.getState().donation.amount,
    annonymousUser: true,
  }

  const { campaignId, institutionId } = store.getState().donation.recipient

  if (campaignId) body.campaignId = campaignId
  if (institutionId) body.institutionId = institutionId

  return api.post(`/maintenances`, body)
}
