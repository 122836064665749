import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { darken } from 'polished'

export const Table = styled.table`
  ${({ theme }) => css`
    display: none;
    height: 55px;
    line-height: 55px;
    text-align: left;
    width: 100%;

    thead {
      color: ${theme.colors.celestialblue};
      font-size: 16px;
      font-weight: bold;

      th:first-child {
        padding-left: 47px;
      }
    }

    ${theme.queries.large} {
      display: table;
    }

    tbody {
      tr {
        border-radius: 10px;
        color: #8b8b8b;
        font-size: 16px;

        td:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding-left: 47px;
        }

        td:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          padding-right: 30px;
        }

        &:hover {
          background: ${darken(0.03, '#fff')};
        }

        &:nth-child(2n + 1) {
          background-color: ${theme.colors.aliceblue};

          &:hover {
            background: ${darken(0.03, theme.colors.aliceblue)};
          }
        }

        svg {
          cursor: pointer;
          vertical-align: middle;
          width: 100%;
        }
      }
    }
  `}
`

export const DonationCard = styled.div`
  ${({ theme }) => css`
    display: none;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 2px 10px #7c7c7c80;
    padding: 20px 25px;
    border: 1px solid #4ba6d3;

    span,
    h2 {
      margin-bottom: 20px;
    }

    h2 {
      font-size: 18px;
    }

    & > div {
      display: flex;
      justify-content: space-between;

      span {
        margin-bottom: 0;
      }

      .value {
        color: #4b99c1;
      }
    }

    & > a {
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }

    & > svg {
      margin-top: 15px;
      align-self: center;
      width: 25px;
    }

    display: flex;

    ${theme.queries.large} {
      display: none;
    }
  `}
`

export const DonationInfo = styled.div`
  ${({ theme }) => css`
    margin-bottom: 30px;
    ${theme.queries.small} {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    ${theme.queries.medium} {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    ${theme.queries.large} {
      display: block;
    }
  `}
`
