import { css } from '@emotion/core'
import styled from '@emotion/styled'

import FlexContainer from '../../FlexContainer'

export const FormControl = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 60px;

    label {
      color: ${theme.colors.jet};
      font-size: 12px;
      position: absolute;
    }

    input,
    textarea {
      border: 0;
      border-bottom: 1px solid ${theme.colors.jet};
      color: ${theme.colors.arsenic};
      font-size: 16px;
      height: 30px;
      margin-top: 20px;
    }

    textarea {
      resize: none;
      padding-top: 15px;
    }

    ${theme.queries.medium} {
      margin-bottom: 30px;
    }
  `}
`

export const FormControlCheckbox = styled(FormControl)`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    input,
    label {
      display: inline;
      position: relative;
    }

    input {
      margin: 0 10px 0 0;
    }

    label {
      font-size: 16px;
    }
  `}
`

export const FormGroupColumn = styled(FlexContainer)`
  ${({ theme }) => css`
    flex-direction: column;

    ${theme.queries.medium} {
      flex-direction: row;
      justify-content: space-between;

      div {
        width: 50%;

        & + div {
          margin-left: 20px;
        }
      }
    }
  `}
`

export const SelectButton = styled.button`
  ${({ theme, active }) => css`
    background: transparent;
    border: 0;
    flex: 1;
    font-size: 14px;
    padding: 0;
    text-align: left;
    position: relative;
    outline: 0;
    cursor: pointer;
    text-align: center;

    &:after {
      bottom: -10px;
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 6px;
      ${active && `background: ${theme.colors.celestialblue}`}
    }

    ${theme.queries.small} {
      & + button {
        text-align: center;
      }
    }
  `}
`
