/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { format, parseISO } from 'date-fns'

import { getDonations } from '../../../../services/donation'
import { getMaintenance } from '../../../../services/maintences'

import { formatMoney, currencies } from '../../../../utils/money'

import { SelectButton } from '../../../DonationWizard/Form/styles'
import FlexContainer from '../../../FlexContainer'
import RecurrentDonations from './RecurrentDonations'
import DonationsList from './DonationsList'

const Donations = {
  LIST: 0,
  RECURRENT: 1,
}

const DonationsTable = () => {
  const [donations, setDonations] = useState([])
  const [recurrentDonations, setRecurrent] = useState([])
  const [selectedDonation, setTable] = useState(Donations.LIST)

  const normalizeDonation = donation => {
    const rawDate = parseISO(donation.createdDate)
    donation.formattedDate = format(rawDate, 'dd/MM/yyyy')
    donation.formattedValue = formatMoney(currencies.BRL)(donation.value)
    return donation
  }

  const fetchDonations = async () => {
    try {
      const [donationsResponse, maintenacesRespoonse] = await Promise.all([
        getDonations(),
        getMaintenance(),
      ])

      const donationsList = donationsResponse.data.results.map(
        normalizeDonation
      )

      const recurrentList = maintenacesRespoonse.data.results.map(
        normalizeDonation
      )

      setDonations(donationsList)
      setRecurrent(recurrentList)
    } catch (error) {
      if (error.data && error.data.message) {
        toast.info(error.data.message)
      }
      toast.error(error)
    }
  }

  useEffect(() => {
    fetchDonations()
  }, [])

  return (
    <div>
      {' '}
      <FlexContainer
        css={theme => css`
          margin-bottom: 30px;
          max-width: 50%;

          button {
            font-size: 18px;
          }

          ${theme.queries.small} {
            max-width: 100%;
          }
        `}
      >
        <SelectButton
          type="button"
          active={selectedDonation === Donations.LIST}
          onMouseDown={() => setTable(Donations.LIST)}
        >
          <FormattedMessage id="pages.profile.historic" />
        </SelectButton>
        <SelectButton
          type="button"
          active={selectedDonation === Donations.RECURRENT}
          onMouseDown={() => setTable(Donations.RECURRENT)}
        >
          <FormattedMessage id="pages.profile.recurrent_donations" />
        </SelectButton>
      </FlexContainer>
      <div>
        {selectedDonation === Donations.LIST && (
          <DonationsList donations={donations} />
        )}
        {selectedDonation === Donations.RECURRENT && (
          <RecurrentDonations donations={recurrentDonations} />
        )}
      </div>
    </div>
  )
}

export default DonationsTable
